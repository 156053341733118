<template>
  <div>
    <v-dialog
      v-model="show_tp"
      content-class="position-absolute top-0"
      width="95%"
    >
      <v-card v-if="loading_tp">
        <v-card-title><h1>loading..</h1></v-card-title>
      </v-card>
      <v-card v-else>
        <mark-info :markData="mark" :lbtype="lbtype"></mark-info>
      </v-card>
    </v-dialog>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Building Transcript
        </h5>
        <!-- <p class="text-sm text-body mb-0">
          Features include sorting, searching, pagination, content-editing, and
          row selection.
        </p> -->
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :options.sync="options"
          :items="items"
          :server-items-length="items_count"
          :loading="loading"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:loading>
            {{ $t("Loading...") }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-select
                  :items="city_info"
                  v-model="select_city"
                  value="Furniture"
                  color="rgba(0,0,0,.6)"
                  class="input-style font-size-input text-light-input placeholder-light border-radius-md select-style mx-3"
                  outlined
                  single-line
                  height="36"
                  @change="onCitySelect()"
                >
                </v-select>
                <v-select
                  :items="customer_building_mission"
                  v-model="select_plan"
                  item-text="district_name"
                  item-value="district_code"
                  value="Furniture"
                  color="rgba(0,0,0,.6)"
                  class="input-style font-size-input text-light-input placeholder-light border-radius-md select-style mx-3"
                  outlined
                  single-line
                  height="36"
                >
                </v-select>

                <v-text-field
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light mx-3"
                  outlined
                  single-line
                  height="36"
                  v-model="keyword"
                  :placeholder="$t('Keyword')"
                  @keydown.enter="getTranscript"
                >
                </v-text-field>

                <v-btn
                  :ripple="false"
                  elevation="0"
                  color="#fff"
                  class="font-weight-bolder btn-default bg-gradient-default py-5 px-6 mx-3 ms-auto"
                  small
                  @click="onOptionChange()"
                >
                  <v-icon class="me-2" size="12">fa fa-search</v-icon>
                  {{ $t("Search") }}
                </v-btn>

                <v-btn
                  :ripple="false"
                  elevation="0"
                  color="#fff"
                  class="font-weight-bolder btn-default bg-gradient-primary py-5 px-6 mx-3 ms-auto"
                  small
                  @click="onReset()"
                >
                  <v-icon class="me-2" size="12">fa fa-search</v-icon>
                  {{ $t("Reset") }}
                </v-btn>
              </v-row>
            </v-toolbar>
            <v-row>
              <v-col cols="6" class="ml-auto d-flex justify-end">
                <v-pagination
                  prev-icon="fa fa-angle-left"
                  next-icon="fa fa-angle-right"
                  class="pagination"
                  color="#cb0c9f"
                  v-model="page"
                  :length="getPageCount()"
                  circle
                  :total-visible="15"
                ></v-pagination>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.district="{ item }">
            {{ getDistrictName(item) }}
          </template>
          <template v-slot:item.region="{ item }">
            {{ getRegionName(item) }}
          </template>
          <template v-slot:item.creditors_num="{ item }">
            {{ getCreditorCount(item) }}
          </template>
          <template v-slot:item.mark.area="{ item }">
            {{ getArea(item) }}
          </template>
          <template v-slot:item.scope_area="{ item }">
            {{ getScopeArea(item) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon large class="mr-2" v-on="on" @click.stop="view(item)"
                  >mdi-briefcase-eye-outline</v-icon
                >
              </template>
              <span>{{ $t("View") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click.stop="export_pdf(item.lbkey)"
                  >mdi-file-pdf-box</v-icon
                >
              </template>
              <span>{{ $t("Export") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#cb0c9f"
              v-model="page"
              :length="getPageCount()"
              circle
              :total-visible="15"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar
      top
      v-model="snackbar.visible"
      :color="snackbar.color"
      class="snackbar-shadow"
      :timeout="timeout"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white mr-5">ni ni-bell-55</v-icon>
        <p class="mb-0">
          <span class="font-size-root font-weight-600">Status</span>
          <br />
          {{ snackbar.message }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TranscriptBase from "./Widgets/TranscriptBase.vue";
import MarkInfo from "./Widgets/MarkInfo.vue";
import CommonWidget from "./Widgets/CommonWidget.vue";
import { customer_building_mission } from "@/definitions.js";
import CommonUtility from "@/util/CommonUtility.js";
export default {
  name: "building-transcript-tables",
  data: (vm) => ({
    selected: [],
    // phone_type_enum: phone_type_enum,
    edit_dialog: false,
    start_menu: false,
    start_date: null,
    end_menu: false,
    end_date: null,
    keyword: "",
    base_url: "/api/v1/building-owners/",
    timeout: 2000,
    lbtype: "B",
    mark: null,
    customer_building_mission: customer_building_mission,
    select_plan: null,
    headers: [
      // {
      //   text: vm.$i18n.t("city"),
      //   cellClass: "border-bottom",
      //   sortable: false,
      //   value: "city",
      //   class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      // },
      {
        text: vm.$i18n.t("district"),
        align: "start",
        cellClass: "border-bottom",
        sortable: false,
        value: "district",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("region"),
        cellClass: "border-bottom",
        sortable: false,
        value: "region",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("building-nos"),
        cellClass: "border-bottom",
        sortable: false,
        value: "nos",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("door_map"),
        cellClass: "border-bottom",
        sortable: false,
        value: "mark.door_map",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("area"),
        cellClass: "border-bottom",
        sortable: false,
        value: "mark.area",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },

      {
        text: vm.$i18n.t("Owner Name"),
        cellClass: "border-bottom",
        sortable: false,
        value: "owner_name",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("Scope"),
        cellClass: "border-bottom",
        sortable: false,
        value: "scope",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("Scope Area"),
        cellClass: "border-bottom",
        sortable: false,
        value: "scope_area",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("creditors_num"),
        cellClass: "border-bottom",
        sortable: false,
        value: "creditors_num",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("actions"),
        cellClass: "border-bottom",
        align: "end",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
    ],
  }),
  mixins: [TranscriptBase, CommonWidget],
  components: {
    MarkInfo,
  },
  mounted() {
    this.get_districts();
    this.getTranscript();
  },
  methods: {
    ...mapGetters(["getToken"]),
    onReset() {
      console.log("on Reset");
      this.page = 1;
      this.start_date = null;
      this.end_date = null;
      this.keyword = null;
      this.getTranscript();
    },
    onOptionChange() {
      this.page = 1;
      this.getTranscript();
    },
    onTriggerMissingOwners() {
      console.log("this select ", this.selected);
    },
    edit(item) {
      this.isCreate = false;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.originItem = JSON.parse(JSON.stringify(item));
      this.edit_dialog = true;
    },
    close() {
      this.onClear();
      this.edit_dialog = false;
    },
    onActionDone(msg) {
      this.close();
      this.getTranscript();
      this.showSuccessAlert(msg); //"Add Customer");
    },
    showSuccessAlert(message) {
      console.debug(message);
    },
    view(item) {
      console.log("show lbkey ", item.lbkey);
      this.show_tp = true;
      // this.SnackbarShow("Info", "hello");

      this.get_transcript_by_key(item.lbkey);
    },
    setViewTranscript(item) {
      this.mark = item["mark"];
      console.log("mark", this.mark);
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
};
</script>
<style scoped>
/* @import "../../../assets/css/jub.css"; */

.download_txt:hover {
  color: blue !important;
  text-decoration: underline;
}
</style>
